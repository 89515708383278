import React from "react"

import { Link } from "gatsby"

import { Wrapper } from "../components/base/wrapper/wrapper"
import { SEO } from "../components/base/seo/seo"

const NorthernIreland = () => (
  <Wrapper>
    <SEO
      title="Northern Ireland"
      description="Business Debtline does not offer free debt advice in Northern Ireland, but can signpost to organisations who do. Free debt advice Northern Ireland."
    />

    <div className="row">
      <div className="offset-2 col-8">
        <h1 className="mb-3">Northern Ireland</h1>

        <p>
          Unfortunately, the Business Debtline does not give debt advice in
          Northern Ireland. If you are looking for debt advice for Northern
          Ireland please contact&nbsp;
          <a href="https://www.adviceni.net/advice">Advice NI</a>.
        </p>

        <hr />

        <p>
          <strong>
            <Link to="/" aria-label="homepage">
              Click here
            </Link>
            &nbsp;to return to the homepage.
          </strong>
        </p>
      </div>
    </div>
  </Wrapper>
)

export default NorthernIreland
